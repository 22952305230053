import { DiscountMappingConstant } from "presentation/constant/DiscountMapping/DiscountMappingConstant";
import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useMemo } from "react";
import { DialogModal, FieldType, HPHButton, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";

const DiscountMappingRejectReasonModal: React.FC = () => {
    const discountMappingVM = useDiscountMappingVM();
    const [discountMappingState] = useDiscountMappingTracked();

    const {currentRow, isShowRejectPad} = discountMappingState;


    const onRejectClicked = () => {  
        discountMappingVM.onReject(currentRow);
    }

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"395px",marginBottom:"24px"}} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentRow.reasonCode || ''}
                fieldValue={currentRow.reasonCode}
                fieldLabel={DiscountMappingConstant.Table.REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'reasonCode'}
                maxLength={200}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => discountMappingVM.onRejectReasonTextAreaChange(fieldValue && fieldValue.toString())} 
                requiredFieldList={['reasonCode']}                    />
        </div>
    , [currentRow.reasonCode, discountMappingVM])
    


    return <><DialogModal style={{  minWidth:'650px', minHeight:'650px' }}
    appendTo='self'
    showButton={false}
    visible={isShowRejectPad}
    positions="center"
    resizable={false}
    header={
        <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">Reject Reason</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={discountMappingVM.cancelRejectReasonModal} tooltipDisable={true}/>
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoRejectReason}
                </CriteriaItemContainer> 

            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton disabled={currentRow.reasonCode?false:true} label={'Reject'} size={'Small'} theme={'Primary'} onClick={onRejectClicked}/>
            </div>
        }
    /></>;
};

export default memo(DiscountMappingRejectReasonModal);