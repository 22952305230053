import { useDiscountMappingVM } from "presentation/hook/DiscountMapping/useDiscountMappingVM";
import { useDiscountMappingTracked } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import DiscountMappingRejectReasonModal from "./DiscountMappingRejectReasonModal";
import DiscountMappingEditPanel from "./RightPanel/DiscountMappingEditPanel";
import DiscountMappingTablePanel from "./Table/DiscountMappingTablePanel";


const DiscountMappingMaintenance: React.FC = () => {
    const [discountMappingState] = useDiscountMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const discountMappingVM = useDiscountMappingVM();
    const { isShowEditPanel } = discountMappingState;


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    discountMappingVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            discountMappingVM.searchAllDiscountMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [discountMappingVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            {!(isShowEditPanel) && <>
                {/* <DiscountMappingTitleBar /> */}
                {discountMappingState.isRejecting && <Loader Indicator="Spinner" size="Medium" />}
                <DiscountMappingTablePanel />
                <div className='im-charge-data-search-confirm-modal-container'>
                    {<DiscountMappingRejectReasonModal />}
                </div>
            </>}
            {(isShowEditPanel) &&
                <SliderPanel
                    isOpen={true}
                    draggable={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<DiscountMappingEditPanel />}
                />}
        </div>
    </>
}

export default memo(DiscountMappingMaintenance);