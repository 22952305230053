import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DiscountMappingProvider } from "presentation/store/DiscountMapping/DiscountMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import DiscountMappingMaintenance from "presentation/view/section/DiscountMapping/DiscountMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const DiscountMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.DISCOUNT_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <DiscountMappingProvider>
                <GridStyles/>
                <DiscountMappingMaintenance/>
            </DiscountMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default DiscountMappingContMain;