import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { DiscountMappingEntity } from "domain/entity/DiscountMapping/DiscountMappingEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { DiscountMappingRepository } from "./DiscountMappingRepo";

export const DiscountMappingRepoImpl = (): DiscountMappingRepository => {
    
    const searchUrl = "/v1/searchAllDiscountMappingList"

    const searchAllDiscountMappingList = async() : Promise<DiscountMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: DiscountMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/discountMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }
    const onUnconfirm = async(rows: DiscountMappingEntity[]): Promise<ResponseEntity> => {
        const unconfirmUrl = "/v1/discountMappingUnconfirm"
        return await axiosPostData(referenceAxiosInstance, `${unconfirmUrl}`, rows);
    }
    const onSubmit = async(rows: DiscountMappingEntity[]): Promise<ResponseEntity> => {
        const submitUrl = "/v1/discountMappingSubmit"
        return await axiosPostData(referenceAxiosInstance, `${submitUrl}`, rows);
    }
    const onApprove = async(rows: DiscountMappingEntity[]): Promise<ResponseEntity> => {
        const approveUrl = "/v1/discountMappingApprove"
        return await axiosPostData(referenceAxiosInstance, `${approveUrl}`, rows);
    }
    const onReject = async(rows: DiscountMappingEntity[]): Promise<ResponseEntity> => {
        const rejectUrl = "/v1/discountMappingReject"
        return await axiosPostData(referenceAxiosInstance, `${rejectUrl}`, rows);
    }

    const onSave = async(row: DiscountMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/discountMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }

    return {
        onSave: onSave,
        onApply: onApply,
        onReject: onReject,
        onApprove: onApprove,
        onSubmit: onSubmit,
        onUnconfirm: onUnconfirm,
        searchAllDiscountMappingList : searchAllDiscountMappingList,
    }
}
