import { DiscountMappingEntity, EMPTY_DISCOUNT_MAPPING_ENTITY } from "domain/entity/DiscountMapping/DiscountMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface DiscountMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffTypeDropdownOptions: DropdownProps[],
    chargeTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    chargeTariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    companyCodeDropdownOptions: DropdownProps[],
}
export interface DiscountMappingViewChangeState extends BaseViewChangeSate {
}

export interface DiscountMappingModel {
    isLoading: boolean,
    isShowEditPanel: boolean,
    isShowRejectPad: boolean,
    isRejecting: boolean,
    afterReject: boolean,
    isAdd: boolean,
    isEdit: boolean,
    dynamicOptions: DiscountMappingDropdownOptions,
    discountMappingList: DiscountMappingEntity[],
    selectedRows: DiscountMappingEntity[],
    updatedRows: DiscountMappingEntity[],
    currentRow: DiscountMappingEntity,
    currentEditRow: DiscountMappingEntity,

}

export const EMPTY_DISCOUNT_MAPPING_MODEL: DiscountMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isShowRejectPad: false,
    isRejecting: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        companyCodeDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        chargeTariffCodeDropdownOptions: {},
        chargeTariffTypeDropdownOptions: []
    },
    discountMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_DISCOUNT_MAPPING_ENTITY },
    currentEditRow: { ...EMPTY_DISCOUNT_MAPPING_ENTITY },
    afterReject: false
}