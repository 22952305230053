export interface DiscountMappingEntity{

    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    billToCompanyCode?: string | null,
    chargeType?: string | null,
    subChargeType?: string | null,
    chargeTariffType?: string | null,
    chargeTariffCode?: string | null,
    effectiveDate?: Date | null,
    expiryDate?: Date | null,
    confirmedDate?: Date | null,
    status?: string | null,
    reasonCode?: string | null,
    discount?: number | null,
    discountType?: string | null,
    discountWithTax?: string | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    activeInd: string | null,
    valueAdjType: string | null,
    mappingType: string,

    [key: string]: string | Date | boolean | number | null | Object | undefined
}

export const EMPTY_DISCOUNT_MAPPING_ENTITY : DiscountMappingEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    billToCompanyCode: '',
    chargeType: '',
    subChargeType: '',
    chargeTariffType: '',
    chargeTariffCode: '',
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    status: 'NEW',
    reasonCode: null,
    discount: null,
    discountType: '',
    discountWithTax: 'Y',
    tariffType: '',
    tariffCode: '',
    activeInd: 'Y',
    valueAdjType: 'DISCOUNT',
    mappingType: "DocumentValueAdjEntity",

}
